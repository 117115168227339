import { ConfigCatProvider, OverrideBehaviour, PollingMode, createFlagOverridesFromMap } from "configcat-react";
import React, { FC, PropsWithChildren } from "react";

// We do not have an SDK key for development
// Any feature flags we want to use need to be overriden locally
const flagOverrides = createFlagOverridesFromMap(
  {
    READ_FULL_DESCRIPTION_AB_TEST: true,
    LISTING_SITE: true,
  },
  // Prefer local if we are in development mode
  process.env.NODE_ENV === "development" ? OverrideBehaviour.LocalOverRemote : OverrideBehaviour.RemoteOverLocal
);

const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigCatProvider
      sdkKey={process.env.NEXT_PUBLIC_CONFIG_CAT_SDK_KEY as string}
      pollingMode={PollingMode.LazyLoad}
      options={{ flagOverrides }}
    >
      {children}
    </ConfigCatProvider>
  );
};

export default FeatureFlagProvider;
