import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useRouter } from "next/router";

import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import FeatureFlagProvider from "components/FeatureFlagProvider";
import "styles/global.css";

polyfillCountryFlagEmojis();

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  const router = useRouter();

  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
      <Analytics />
      <SpeedInsights route={router.pathname} />
      <FeatureFlagProvider>
        <Component {...pageProps} />
      </FeatureFlagProvider>
    </QueryClientProvider>
  );
};

export default appWithTranslation(App);
